$(document).on("turbolinks:load", function (){

  if ( document.querySelector('body.map_revisions.new, body.map_revisions.edit') ) {
    var canvas = new fabric.Canvas('c', {
        preserveObjectStacking: true
      });
    var preview_canvas = new fabric.StaticCanvas('preview-canvas',{
      width: 50,
      height: 50
    })

    var $ = function(id){return document.getElementById(id)};
    var mapMode = "edit";
    var mapId = document.getElementById('map_revision_map_id').value
    var currentUserId = document.getElementById('map_revision_user_id').value
    var mapRevisionId = ( document.getElementById('map_revision_id') ) ? parseInt(document.getElementById('map_revision_id').value) : false;
    var itemSelection;
    var isItemSelected = false;
    var isAddItemSelected = false;
    var isDrawItemSelected = false;
    var pausePanning = false;
    var originalZoom;
    //var opacity = 0.7;
    var globalOptions = {opacity: 0.7};
    var drawItemId;
    var lastIconSelection;
    var lastDrawOptions;
    var scale;
    var opacity;
    var cloned_item;
    var patternSourceCanvas;
    var obj;
    var zoomStartScale;
    var changeDist;
    var startDist;
    var currentX;
    var currentY;
    var xChange;
    var yChange;
    var lastX;
    var lastY;

    const form = document.querySelector('[id*="_map_revision"]');
    if (form) form.addEventListener('submit', updateFormBeforeSubmit);

    canvas.selection = false;
    canvas.isDrawingMode = false;
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.NUM_FRACTION_DIGITS = 8;

    //override toObject of fabric.Pattern
    var toFixed = fabric.util.toFixed;
    fabric.Pattern.prototype.toObject = function(propertiesToInclude) {
      var NUM_FRACTION_DIGITS = fabric.Object.NUM_FRACTION_DIGITS,
        source, object;
      if (typeof this.source === "function") {
        source = String(this.source);
      } else if (typeof this.source.src === "string") {
        source = this.source.src;
      } else if (typeof this.source === "object" && this.source.toDataURL) {
        source = this.source.toDataURL();
      }
      object = {
        type: "pattern",
        source: source,
        repeat: this.repeat,
        crossOrigin: this.crossOrigin,
        offsetX: toFixed(this.offsetX, NUM_FRACTION_DIGITS),
        offsetY: toFixed(this.offsetY, NUM_FRACTION_DIGITS),
        patternTransform: this.patternTransform ? this.patternTransform.concat() : null
      };
      fabric.util.populateWithProperties(this, object, propertiesToInclude);
      return object;
    };

    var cachedWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      var newWidth = window.innerWidth;
      if (newWidth != cachedWidth) {
        resizeCanvas();
        cachedWidth = newWidth;
      }
    }, false);

    loadImage();
    // window.addEventListener('load', () => {loadImage();}, false);

    canvas.on("after:render", function(){
      canvas.calcOffset();
    });

    canvas.on('mouse:wheel', function(opt) {
      var delta = opt.e.deltaY;
      var pointer = canvas.getPointer(opt.e);
      var zoom = canvas.getZoom();
      zoom = zoom + delta/200;
      if (zoom > 20) zoom = 20;
      if (zoom < originalZoom) zoom = originalZoom, canvas.setViewportTransform([originalZoom,0,0,originalZoom,0,0]);
      canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });

    canvas.on({
      // 'object:moved' : ,
      // 'object:scaled' : ,
      // 'object:rotated' : ,
      // 'object:skewed' : ,
      // 'object:modified' : ,
    });

    canvas.on('object:added', function (opt) {
      setPrompt();
      if (mapMode == "draw") {
        opt.target.mapLegendItemId = canvas.freeDrawingBrush.mapLegendItemId
        opt.target.featureId = canvas.freeDrawingBrush.featureId
        opt.target.isActive = true
      }
    });

    canvas.on('object:removed', function (opt) {
      setPrompt();
    });

    canvas.on('object:moved', function (opt) {
    })

    canvas.on('selection:created', function (opt) {
      console.log(opt.target)
      isItemSelected = true;
      pausePanning = true;
      showSelectionSection();
      updateSelectionOptions(opt.target);
    });

    canvas.on('selection:updated', function (opt) {
      isItemSelected = true;
      showSelectionSection();
      updateSelectionOptions(opt.target);
    });

    canvas.on('selection:cleared', function (opt) {
      isItemSelected = false;
      pausePanning = false;
      hideSelectionButtons();
    });

    canvas.on('path:created', function (opt) {
      delete canvas.backgroundImage.clipPath
    })

    canvas.on('mouse:up', function (opt) {
    })

    // Some things to do when page initially loads
    // Import map image automatically
    // https://stackoverflow.com/questions/54258353/resize-canvas-to-fit-the-image-size-and-scale-with-fabricjs
    // https://stackoverflow.com/questions/44823070/fit-and-prevent-image-position-inside-canvas

    var deleteSelectionEl = $('delete-selection'),
        selectionActiveSettingEl = $('selection-active-setting'),
        bringToFrontEl = $('bring-to-front'),
        sendToBackEl = $('send-to-back'),
        moveToBottomEl = $('move-to-bottom'),
        drawModeEl = $('draw-mode'),
        editModeEl = $('edit-mode'),
        addModeEl = $('add-mode'),
        panModeEl = $('pan-mode'),
        resetViewEl = $('reset-view'),
        endLineEl = $('end-line'),
        itemScaleEl = $('item-size-scale'),
        itemOpacityEl = $('item-opacity-scale'),
        drawingLineWidthEl = $('drawing-line-width'),
        drawingLineValueEl = $('drawing-line-value'),
        lotConstructionStartedItemEl = $('lot-construction-started-item'),
        finalStabilizationItemEl = $('final-stabilization-item'),
        concreteWashOutEl = $('concrete-wash-out'),
        dischargeOutfallEl = $('discharge-outfall'),
        inletProtectionEl = $('inlet-protection'),
        constructionEntranceBmpEl = $('construction-entrance-bmp'),
        temporaryStabilizationEl = $('temporary-stabilization'),
        lotConstructionStartedEl = $('lot-construction-started'),
        finalStabilizationEl = $('final-stabilization'),
        // siltFenceEl = $('silt-fence'),
        // strawWattleEl = $('straw-wattle'),
        // perimeterWallEl = $('perimeter-wall'),
        // lotBmpEl = $('lot-bmp'),
        constructionLimitsEl = $('construction-limits'),
        siltFenceDrawEl = $('silt-fence-draw'),
        siltFenceLineEl = $('silt-fence-line'),
        strawWattleDrawEl = $('straw-wattle-draw'),
        strawWattleLineEl = $('straw-wattle-line'),
        heavyweightWattleDrawEl = $('heavyweight-wattle-draw'),
        heavyweightWattleLineEl = $('heavyweight-wattle-line'),
        perimeterWallDrawEl = $('perimeter-wall-draw'),
        perimeterWallLineEl = $('perimeter-wall-line'),
        lotBmpDrawEl = $('lot-bmp-draw'),
        lotBmpLineEl = $('lot-bmp-line'),
        spillKitEl = $('spill-kit'),
        waterSourceEl = $('water-source'),
        portableToiletEl = $('portable-toilet'),
        projectSignEl = $('project-sign'),
        aboveGroundStorageTankEl = $('above-ground-storage-tank'),
        storageAreaEl = $('storage-area'),
        floatingTurbidityBarrierEl = $('floating-turbidity-barrier'),
        sedimentBasinEl = $('sediment-basin'),
        constructionStagingAreaEl = $('construction-staging-area'),
        stockpileEl = $('stockpile'),
        dustControlEl = $('dust-control'),
        trashContainerEl = $('trash-container'),
        dewateringEl = $('dewatering'),
        swpppEl = $('swppp'),
        directionOfFlowEl = $('direction-of-flow'),
        mapRevisionSubmitEl = $('map_revision_submit'),
        rockBermDrawEl = $('rock-berm-draw'),
        rockBermLineEl = $('rock-berm-line'),
        ripRapDrawEl = $('riprap-draw'),
        hydroseedDrawEl = $('hydroseed-draw'),
        geotextileDrawEl = $('geotextile-draw'),
        constructionFenceDrawEl = $('construction-fence-draw'),
        constructionFenceLineEl = $('construction-fence-line'),
        mulchDrawEl = $('mulch-draw'),
        erosionControlBlanketDrawEl = $('erosion-control-blanket-draw'),
        seedCrimpMulchDrawEl = $('seed-crimp-mulch-draw'),
        surfaceRougheningDrawEl = $('surface-roughening-draw'),
        lotAccessBmpDrawEl = $('lot-access-bmp'),
        turbidityCurtainDrawEl = $('turbidity-curtain-draw'),
        turbidityCurtainLineEl = $('turbidity-curtain-line'),
        triangularFilterDikeEl = $('triangular-filter-dike'),
        lotRemovedIconEl = $('lot-removed-icon'),
        blueTextBoxEl = $('blue-text-box'),
        redTextBoxEl = $('red-text-box'),
        rockSockEl = $('rock-sock'),
        curbCutbackDrawEl = $('curb-cutback-draw'),
        curbCutbackLineEl = $('curb-cutback-line'),
        northArrowEl = $('north-arrow'),
        stormwaterManagementFacilityEl = $('stormwater-management-facility'),
        diversionDitchLineEl = $('diversion-ditch-line'),
        disturbedSoilEl = $('disturbed-soil-draw'),
        checkDamIconEl = $('check-dam-icon'),
        temporaryOutletProtectionIconEl = $('temporary-outlet-protection-icon'),
        receivingWatersIconEl = $('receiving-waters-icon'),
        surfaceWaterEl = $('surface-water-icon'),
        wetlandEl = $('wetland-icon'),
        sinkholeEl = $('sinkhole-icon'),
        drywellEl = $('drywell-icon'),
        wireSiltFenceDrawEl = $('wire-silt-fence-draw'),
        wireSiltFenceLineEl = $('wire-silt-fence-line'),
        mixingStationEl = $('mixing-station-icon'),
        lotAccessWattleEl = $('lot-access-wattle-icon'),
        lotAccessCurlexEl = $('lot-access-curlex-icon'),
        lotAccessCutbackEl = $('lot-access-cutback-icon'),
        lotAccessRockpadEl = $('lot-access-rockpad-icon'),
        lotAccessMulchEl = $('lot-access-mulch-icon'),
        lotAccessTrackoutEl = $('lot-access-trackout-icon'),
        vegetativeBufferDrawEl = $('vegetative-buffer-draw'),
        seedDrawEl = $('seed-draw'),
        notOursEl = $('not-ours-icon'),
        fullScreenEl = $('full-screen'),
        straightLineSection = $('straight-line-section'),
        straightLineEl = $('straight-line'),
        eraseEl = $('erase'),
        eraseDrawEl = $('erase-draw'),
        eraseBoxEl = $('erase-box');

    function loadBackgroundImage() {
      var canvasSizer = document.getElementById("canvas-sizer");
      var img_url = document.getElementById('map_revision_map_url').value;
      fabric.Image.fromURL(img_url, function(oImg) {
          var map_ratio = oImg.width / oImg.height
          var height, width;
          canvas.setBackgroundImage(oImg);

          width = canvasSizer.offsetWidth;
          height = width / map_ratio;

          if (height > canvasSizer.offsetHeight) {
            height = canvasSizer.offsetHeight
            width = height * map_ratio
          }

          oImg.scaleToHeight(height);
          canvas.setDimensions({ width: width, height: height });
          canvas.calcOffset();
          originalZoom = canvas.getZoom();
        }, {
          crossOrigin: 'anonymous'
      });
    };

    function loadPreviousMapJSON() {
      var latest_map = document.getElementById('map_revision_latest_canvas_string').value
      if ( latest_map == "no_canvas_data" ) {
        // Do nothing
      } else {
        canvas.loadFromJSON(latest_map, function() {
          canvas.renderAll.bind(canvas);

          // This code is used to lock items added during previous revisions
          //
          // obj = canvas.getObjects();
          // obj.forEach(function(item, i) {
          //   if (item.mapRevisionId == mapRevisionId) {
          //     // Item is editable and deletable
          //   } else if ([9,19].includes(item.mapLegendItemId)) {
          //     // Items 9 and 19 should remain editable across edits
          //   } else {
          //     // Item cannot be edited or deleted
          //     item.hasControls = false,
          //     item.lockMovementX = true,
          //     item.lockMovementY = true,
          //     item.lockScalingX = true,
          //     item.lockScalingY = true,
          //     item.lockRotation = true,
          //     item.isDeletable = false
          //   }
          // });

          loadBackgroundImage()

          resizeCanvas();
          originalZoom = canvas.getZoom();
        });
      }
    };

    function loadImage() {
      var latest_map = document.getElementById('map_revision_latest_canvas_string').value
      var url = document.getElementById('map_revision_map_url').value
      var row_width = document.getElementById("canvas-sizer").offsetWidth;
      if ( latest_map == "no_canvas_data" ) {

        loadBackgroundImage();

      } else {

        loadPreviousMapJSON();

      }
    };

    function updateSelectionOptions(obj) {
      if (obj === undefined) {

        // Hide certain buttons for items added during previous edits
        deleteSelectionEl.style.display = 'none'
        document.getElementById('item-scale-section').style.display = 'none';
        document.getElementById('item-opacity-section').style.display = '';

      } else if (obj.type == "path" && !obj.fromSVG) {
        // For items that are paths but not an SVG, hide the size slider
        deleteSelectionEl.style.display = ''
        document.getElementById('item-scale-section').style.display = 'none';
        document.getElementById('item-opacity-section').style.display = '';
        //itemOpacityEl.value = obj.opacity
        if(obj.opacity){
          itemOpacityEl.value = obj.opacity
          document.getElementById('item-opacity-value').innerHTML = obj.opacity
        }

      } else {
        // Show all buttons for new items
        deleteSelectionEl.style.display = ''

        document.getElementById('item-scale-section').style.display = '';
        itemScaleEl.value = ( Math.round(obj.width * obj.scaleX) )
        document.getElementById('item-size-value').innerHTML = ( Math.round(obj.width * obj.scaleX) )

        document.getElementById('item-opacity-section').style.display = '';
        //itemOpacityEl.value = obj.opacity
        if(obj.opacity){
          itemOpacityEl.value = obj.opacity
          document.getElementById('item-opacity-value').innerHTML = obj.opacity
        }

      };
      // Status Options
      updateSelectionStatus(obj);
    };

    function setItemScale(name) {
      if (["Lot Construction Started", "Final Stabilization"].includes(name)) {
        updateItemScale(10)
      } else {
        updateItemScale(30)
      }
    }

    function updateItemScale(value) {
      itemScaleEl.value = ( Math.round(value) )
      document.getElementById('item-size-value').innerHTML = ( Math.round(value) )
    }

    function showSelectionSection() {
      if (isItemSelected) {
        deleteSelectionEl.style.display = '';
        selectionActiveSettingEl.style.display = '';
        bringToFrontEl.style.display = '';
        sendToBackEl.style.display = '';
        moveToBottomEl.style.display = '';
        updateSelectionOptions(canvas.getActiveObject());
      }
    };

    function hideSelectionButtons() {
      // Hide all selection buttons
      deleteSelectionEl.style.display = 'none';
      selectionActiveSettingEl.style.display = 'none';
      bringToFrontEl.style.display = 'none';
      sendToBackEl.style.display = 'none';
      moveToBottomEl.style.display = 'none';
    };

    function updateSelectionStatus(opt) {
      if (opt.isActive || opt.isActive === undefined) {
        selectionActiveSettingEl.style.display = '';
        selectionActiveSettingEl.innerHTML = "Set Inactive";
      } else if (!opt.isActive) {
        selectionActiveSettingEl.style.display = '';
        selectionActiveSettingEl.innerHTML = "Set Active";
      } else {
        // Do nothing
      }
    };

    function showDrawingSection() {
      document.getElementById('drawing-options-section').style.display = ''
    };

    function hideDrawingSection() {
      document.getElementById('drawing-options-section').style.display = 'none'
    };

    function showItemScaleSection() {
      document.getElementById('item-scale-section').style.display = ''
    };

    function hideItemScaleSection() {
      document.getElementById('item-scale-section').style.display = 'none'
    };

    function showItemOpacitySection() {
      document.getElementById('item-opacity-section').style.display = ''
    };

    function hideItemOpacitySection() {
      document.getElementById('item-opacity-section').style.display = 'none'
    };

    function showDrawButton() {
      document.getElementById('draw-button').style.display = ''
    };

    function showItemsButton() {
      document.getElementById('items-button').style.display = ''
    };

    function hideDrawButton() {
      document.getElementById('draw-button').style.display = 'none'
    };

    function hideItemsButton() {
      document.getElementById('items-button').style.display = 'none'
    };

    function updateMapMode(mode) {
      switch (mode) {
        case "pan":
          mapMode = mode;
          pausePanning = true;
          canvas.isDrawingMode = false;
          canvas.selection = false;
          canvas.forEachObject(function(o) {
            o.selectable = false;
            o.eventable = false;
          });
          panModeEl.classList.add("active");
          drawModeEl.classList.remove("active");
          editModeEl.classList.remove("active");
          addModeEl.classList.remove("active");
          straightLineSection.style.display = 'none';
          hideDrawingSection();
          hideDrawButton();
          hideSelectionButtons();
          hideItemScaleSection();
          hideItemOpacitySection();
          hideItemsButton();
          resetPreview();
          resetBrush();
          setItemSelection(null)
          break;
        case "draw":
          itemOpacityEl.value = 0.7;
          document.getElementById('item-opacity-value').innerHTML = 0.7
          mapMode = mode;
          pausePanning = true;
          canvas.isDrawingMode = true;
          canvas.selection = false;
          canvas.forEachObject(function(o) {
            o.selectable = false;
            o.eventable = false;
          });
          panModeEl.classList.remove("active");
          drawModeEl.classList.add("active");
          editModeEl.classList.remove("active");
          addModeEl.classList.remove("active");
          straightLineSection.style.display = '';
          document.getElementById('straight-line').checked = false;
          endLineEl.style.display = 'none';
          showDrawingSection();
          showDrawButton();
          hideSelectionButtons();
          hideItemScaleSection();
          showItemOpacitySection();
          hideItemsButton();
          resetPreview();
          resetBrush();
          if (drawItemId != null && lastDrawOptions != null){
            globalOptions = lastDrawOptions;
            setDrawingBrush(drawItemId);
          } 
          break;
        case "edit":
          mapMode = mode;
          pausePanning = false;
          canvas.isDrawingMode = false;
          canvas.selection = true;
          canvas.forEachObject(function(o){
            if ( o.mapRevisionId && o.mapRevisionId != mapRevisionId ) {
              // items added during previous updates
              o.selectable = true;
              // o.eventable = o.isDeletable;
            } else {
              // items added during the current update
              o.selectable = true;
              o.eventable = true;
            }
          })
          panModeEl.classList.remove("active");
          drawModeEl.classList.remove("active");
          editModeEl.classList.add("active");
          addModeEl.classList.remove("active");
          straightLineSection.style.display = 'none';
          hideDrawingSection();
          showSelectionSection();
          hideItemsButton();
          hideDrawButton();
          resetPreview();
          break;
        case "add":
          itemOpacityEl.value = 0.7;
          document.getElementById('item-opacity-value').innerHTML = 0.7
          mapMode = mode;
          pausePanning = true;
          canvas.isDrawingMode = false;
          canvas.selection = false;
          canvas.forEachObject(function(o) {
            o.selectable = false;
            o.eventable = false;
          });
          panModeEl.classList.remove("active");
          drawModeEl.classList.remove("active");
          editModeEl.classList.remove("active");
          addModeEl.classList.add("active");
          straightLineSection.style.display = 'none';
          hideDrawingSection();
          hideDrawButton();
          hideSelectionButtons();
          showItemScaleSection();
          showItemOpacitySection();
          showItemsButton();
          if (lastIconSelection != null){
            setPreview(lastIconSelection)
            setItemSelection(lastIconSelection)
          } else {
            resetPreview();
          }
          resetBrush();
          break;
      }
    };

    function resizeCanvas() {
      // jsfiddle.net/wq3pyhhu/8/
      var canvasSizer = document.getElementById("canvas-sizer");
      var map_ratio = canvas.backgroundImage.width / canvas.backgroundImage.height
      scale = canvas.backgroundImage.scaleX;
      var height, width;
      width = canvasSizer.offsetWidth;
      height = width / map_ratio;

      if (height > canvasSizer.offsetHeight) {
        height = canvasSizer.offsetHeight
        width = height * map_ratio
      }

      var zoom = (width / canvas.backgroundImage.width) / scale;
      canvas.setDimensions({ width: width, height: height });
      canvas.setViewportTransform([zoom , 0, 0, zoom , 0, 0])


    };

    // Define what the buttons do


    resetViewEl.onclick = function () {
      var delta = new fabric.Point(0,0);
      canvas.relativePan(delta);
      canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
      resizeCanvas();
    };

    endLineEl.onclick = function () {
      if (straightLineEl.checked) {
        points = [];
        lines = [];
        var oldColor
        if (lastDrawOptions["color"] && lastDrawOptions["opacity"]){
          oldColor = hexToRgba(lastDrawOptions["color"], lastDrawOptions["opacity"]);
        } else if (lastDrawOptions["color"]){
          oldColor = lastDrawOptions["color"];
        } else {
          oldColor = "rgb(0,0,0)";
        }
        drawingLineWidthEl.value = 3;
        drawingLineValueEl.innerHTML = drawingLineWidthEl.value;
        var line = new fabric.Line(
          [
          ],
          {
            stroke: oldColor,
            strokeDashArray: canvas.freeDrawingBrush.strokeDashArray,
            hasControls: true,
            hoverCursor: "default",
            originX: "center",
            originY: "center",
            selectable: true,
            connector: true,
            lockScalingX: true,
            lockScalingY: true,
            featureId: canvas.freeDrawingBrush.featureId
          }
        )
        //updateMapMode("add")
        showDrawingSection()
        //hideItemOpacitySection()
        hideItemScaleSection()
        setItemSelection(line)
      }
    };

    // zoomInEl.onclick = function () {
    //   if (canvas.getZoom() < 3) {
    //       canvas.setZoom(canvas.getZoom() + 0.1);
    //   }
    // };
    //
    // zoomOutEl.onclick = function () {
    //   if (canvas.getZoom() < 1) {
    //     canvas.setZoom(1);
    //   } else if (canvas.getZoom() > 1) {
    //     canvas.setZoom(canvas.getZoom() - 0.1);
    //   }
    // };

    drawModeEl.onclick = function () {
      mapMode = "draw";
      updateMapMode("draw");
      points = []
      lines = []
    };

    editModeEl.onclick = function () {
      mapMode = "edit";
      updateMapMode("edit");
      points = []
      lines = []
    };

    addModeEl.onclick = function () {
      mapMode = "add";
      updateMapMode("add");
      points = []
      lines = []
    };

    panModeEl.onclick = function () {
      mapMode = "pan";
      updateMapMode("pan");
      points = []
      lines = []
    };


    ////////// Panning and Zooming
    //////////////////////////////

    var lastX
    var lastY
    // https://stackoverflow.com/questions/45110576/fabricjs-touch-pan-zoom-entire-canvas
    canvas.on({
        'touch:gesture': function(e) {
            if (e.e.touches && e.e.touches.length == 2) {
              pausePanning = true;
              canvas.selction = false;

              // https://stackoverflow.com/questions/57269095/fabric-js-with-gesture-how-to-prevent-zooming-on-touch-devices
              var zoomStartPoint = new fabric.Point(e.self.x, e.self.y);
              var changeX = e.e.touches[0].clientX - e.e.touches[1].clientX;
              var changeY = e.e.touches[0].clientY - e.e.touches[1].clientY;
              if (e.self.state == "start") {
                zoomStartScale = canvas.getZoom();
                scale = 1
                startDist = Math.hypot(changeX,changeY);
              }
              if (e.self.state == "change") {
                changeDist = Math.hypot(changeX,changeY);
                scale = changeDist/startDist;
              }
              var delta = zoomStartScale * scale;
              if (delta > 4) delta = 4;
              if (delta < 0.25) delta = 0.25;
              canvas.zoomToPoint(zoomStartPoint, delta);
              e.e.preventDefault();
              e.e.stopPropagation();
              pausePanning = false;
            }
        },
        'object:selected': function() {
          pausePanning = true;
        },
        'selection:cleared': function() {

          pausePanning = false;
        },
        'touch:drag': function(e) {
            if (pausePanning == false && canvas.isDrawingMode == false && undefined != e.self.x && undefined != e.self.y) {
              if (  e.e.touches && e.e.touches.length == 1 &&
                    originalZoom != canvas.getZoom() ) {

                canvas.selection = false
                currentX = e.self.x;
                currentY = e.self.y;
                xChange = currentX - lastX;
                yChange = currentY - lastY;

                if( (Math.abs(currentX - lastX) <= 50) && (Math.abs(currentY - lastY) <= 50)) {
                    var delta = new fabric.Point(xChange, yChange);
                    canvas.relativePan(delta);
                }

                lastX = currentX;
                lastY = currentY;
              }
            }
        }
    });

    // https://stackoverflow.com/questions/30466322/fabricjs-pan-and-zoom
    // https://stackoverflow.com/questions/34423822/how-to-implement-canvas-panning-with-fabric-js
    // panUpEl.onclick = function() {
    //   var units = 25;
    //   var delta = new fabric.Point(0,units);
    //   if (canvas.viewportTransform[5] > 0) {
    //     canvas.viewportTransform[5] = 0
    //   }
    // };
    // panDownEl.onclick = function() {
    //   var units = 25;
    //   var delta = new fabric.Point(0,-units);
    //   canvas.relativePan(delta);
    //   var bottomEndPoint = canvas.getHeight() * (canvas.viewportTransform[0] - 1);
    //   if (canvas.viewportTransform[5] >= 0 || -bottomEndPoint > canvas.viewportTransform[5]) {
    //     canvas.viewportTransform[5] = (canvas.viewportTransform[5] >= 0) ? 0 : -bottomEndPoint;
    //   };
    //   // maxTransform = ((canvas.getHeight() * canvas.getZoom() * -1) - (canvas.getHeight() * -1));
    //   // if (canvas.viewportTransform[5] < canvas.getHeight() - (canvas.getHeight() * canvas.getZoom())) {
    //   //   canvas.viewportTransform[5] = canvas.getHeight() - (canvas.getHeight() * canvas.getZoom());
    //   // }
    // };
    // panLeftEl.onclick = function() {
    //   var units = 25;
    //   var delta = new fabric.Point(units,0) ;
    //   canvas.relativePan(delta) ;
    // };
    // panRightEl.onclick = function() {
    //   var units = 25;
    //   var delta = new fabric.Point(-units,0);
    //   canvas.relativePan(delta);
    // };

    function updateFormBeforeSubmit(event) {
      // canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
      event.preventDefault();
      resizeCanvas();
      var map = canvas.toDataURL({
        multiplier: 2,
        format: 'jpeg',
        quality: 1
      });
      var json = canvas.toJSON(['isActive', 'mapRevisionId', 'mapLegendItemId', 'featureId']);
      var json_string = JSON.stringify(json);
      var details = document.getElementById('map_revision_details').value
      var submit_type
      if ( document.querySelector('body.map_revisions.edit') ) {
        submit_type = 'PATCH'
      } else {
        submit_type = 'POST'
      }
      
      console.log(event.srcElement)
      jQuery.ajax({
        xhr: function() {
          var xhr = new window.XMLHttpRequest();
          var progressBarData = document.getElementById("data-upload-progress");
          //Upload progress
          xhr.upload.addEventListener("progress", function(evt){
            if (evt.lengthComputable) {
              //Do something with upload progress
              var percentComplete = (evt.loaded/evt.total)*100;
              percentComplete = Math.floor(percentComplete);
              progressBarData.style["width"] = percentComplete + "%";
            }
          }, false);
          //Download progress
          // xhr.addEventListener("progress", function(evt){
          //   if (evt.lengthComputable) {
          //     var percentComplete = evt.loaded / evt.total;
          //     //Do something with download progress
          //     console.log(percentComplete);
          //   }
          // }, false);
          return xhr;
        },
        url: event.srcElement.action,
        type: submit_type,
        dataType: "JSON",
        data: {
          map_revision: {
            map_id: mapId,
            user_id: currentUserId,
            canvas_data_string: json_string,
            details: details
          }
        },
        success: function ( data, textStatus, jqXHR ) {
          console.log("Successfully submit form, now updating map...")
          console.log(data.redirect_url)
          window.location.replace(data.redirect_url);
          //console.log(data)
          // jQuery.ajax({
          //   xhr: function() {
          //     var xhr = new window.XMLHttpRequest();
          //     var progressBarImage = document.getElementById("image-upload-progress");
          //     //Upload progress
          //     xhr.upload.addEventListener("progress", function(evt){
          //       if (evt.lengthComputable) {
          //         //Do something with upload progress
          //         var percentComplete = (evt.loaded/evt.total)*100;
          //         percentComplete = Math.floor(percentComplete);
          //         progressBarImage.style["width"] = percentComplete + "%";
          //       }
          //     }, false);
          //     //Download progress
          //     // xhr.addEventListener("progress", function(evt){
          //     //   if (evt.lengthComputable) {
          //     //     var percentComplete = evt.loaded / evt.total;
          //     //     //Do something with download progress
          //     //     console.log(percentComplete);
          //     //   }
          //     // }, false);
          //     return xhr;
          //   },
          //   url: "/sites/" + data.site_id + "/maps/" + data.map_id + "/map_revisions/" + data.map_revision_id,
          //   type: "PATCH",
          //   dataType: "JSON",
          //   data: {
          //     map_revision: {
          //       map_file: map
          //     }
          //   },
          //   success: function ( data, textStatus, jqXHR ) {
          //     console.log(data.redirect_url)
          //     window.location.replace(data.redirect_url);
          //   },
          //   error: function ( jqXHR, textStatus, error ) {
          //     console.log(error)
          //   }
          // })
        },
        error: function ( jqXHR, textStatus, error ) {
          console.log(error)
        }
      })

      
      
    };

    var promptLotStabilization = false
    var promptGrading = false

    function setPrompt() {
      promptLotStabilization = false;
      promptGrading = false;
      var objs = canvas.getObjects();
      objs.forEach(function(item, i) {
        if (item.prompt == 'lot') {
          promptLotStabilization = true
        } else if (item.prompt == 'grading') {
          promptGrading = true
        } else {}
      });

      if (promptLotStabilization && promptGrading) {
        $('map_revision_submit').setAttribute('data-title', "Confirm");
        $('map_revision_submit').setAttribute('data-confirm', "Be sure to update the Grading & Stabilization Log and the Lot Stabilization Log.");
        $('map_revision_submit').setAttribute('data-commit', "Ok");
      } else if (promptLotStabilization) {
        $('map_revision_submit').setAttribute('data-title', "Confirm");
        $('map_revision_submit').setAttribute('data-confirm', "Be sure to update the Lot Stabilization Log.");
        $('map_revision_submit').setAttribute('data-commit', "Ok");
      } else if (promptGrading) {
        $('map_revision_submit').setAttribute('data-title', "Confirm");
        $('map_revision_submit').setAttribute('data-confirm', "Be sure to update the Grading & Stabilization Log.");
        $('map_revision_submit').setAttribute('data-commit', "Ok");
      } else {
        $('map_revision_submit').removeAttribute('data-title');
        $('map_revision_submit').removeAttribute('data-confirm');
        $('map_revision_submit').removeAttribute('data-commit');
      }
    };

    function setItemSelection(item) {
      itemSelection = item;

      if(mapMode == "add" && itemSelection.type != "line"){
        lastIconSelection = item;
      }

      itemOpacityEl.value = 0.7;
      document.getElementById('item-opacity-value').innerHTML = 0.7


      if (item === null) {
        return
      }

      if(itemSelection.type != "line"){
        endLineEl.style.display = "none";
      }

      if(item.opacity){
        if(mapMode != "draw"){
          itemOpacityEl.value = item.opacity
          document.getElementById('item-opacity-value').innerHTML = item.opacity
        } else {
          itemOpacityEl.value = globalOptions.opacity
          document.getElementById('item-opacity-value').innerHTML = globalOptions.opacity
        }
        //opacity = item.opacity
      }

      // Add preview image of selection
      setPreview(itemSelection);
    }

    function hexToRgba(hex, op) {
      let alpha = false,
        h = hex.slice(hex.startsWith('#') ? 1 : 0);
      if (h.length === 3) h = [...h].map(x => x + x).join('');
      else if (h.length === 8) alpha = true;
      h = parseInt(h, 16);
      return (
        'rgb' +
        (alpha ? 'a' : '') +
        '(' +
        (h >>> (alpha ? 24 : 16)) +
        ', ' +
        ((h & (alpha ? 0x00ff0000 : 0x00ff00)) >>> (alpha ? 16 : 8)) +
        ', ' +
        ((h & (alpha ? 0x0000ff00 : 0x0000ff)) >>> (alpha ? 8 : 0)) +
        ', ' +
        op +
        ')'
      );
    };


    function setDrawingBrush(feature_id) {
      var brush
      var color
      var initialZoom = 0.35;
      var opts = globalOptions;
      lastDrawOptions = globalOptions;
      color = hexToRgba(opts["color"], itemOpacityEl.value)
      resetPreview();
      if (opts.type == "square") {
        brush = new fabric.PatternBrush(canvas);
        var obj = new fabric.Rect({
          height: 25,
          width: 25,
          fill: color
        })
        patternSourceCanvas = createDrawCanvas({
          height: 50 * initialZoom,
          width: 50 * initialZoom,
          obj: obj,
          initialZoom: initialZoom,
          color: color
        });
        brush.source = patternSourceCanvas.getElement();
        canvas.freeDrawingBrush = brush
        canvas.freeDrawingBrush.color = color
        drawingLineWidthEl.value = 25
        straightLineSection.style.display = 'none';
        updateDrawCanvases(patternSourceCanvas, obj, feature_id)
      }
      else if ( opts.type == "circle" ) {
        brush = new fabric.PatternBrush(canvas);
        var obj = new fabric.Circle({
          radius: 12,
          fill: color
        })
        patternSourceCanvas = createDrawCanvas({
          height: 50 * initialZoom,
          width: 50 * initialZoom,
          obj: obj,
          initialZoom: initialZoom,
          color: color
        });
        brush.source = patternSourceCanvas.getElement();
        canvas.freeDrawingBrush = brush
        canvas.freeDrawingBrush.color = color
        drawingLineWidthEl.value = 25
        straightLineSection.style.display = 'none';
        updateDrawCanvases(patternSourceCanvas, obj, feature_id);
      }
      else {
        brush = new fabric.PencilBrush(canvas);
        canvas.freeDrawingBrush = brush
        canvas.freeDrawingBrush.color = color
        canvas.freeDrawingBrush.strokeDashArray = opts.dashArray
        canvas.freeDrawingBrush.strokeLineCap = opts.lineCap
        drawingLineWidthEl.value = 3
        // Reset points and create template line for straight line ability
        points = []
        lines = []
        var line = new fabric.Line(
          [
          ],
          {
            stroke: canvas.freeDrawingBrush.color,
            strokeDashArray: canvas.freeDrawingBrush.strokeDashArray,
            hasControls: true,
            hoverCursor: "default",
            originX: "center",
            originY: "center",
            selectable: true,
            connector: true,
            lockScalingX: true,
            lockScalingY: true,
            featureId: canvas.freeDrawingBrush.featureId
          }
        )
        setItemSelection(line);
      }


      drawingLineValueEl.innerHTML = drawingLineWidthEl.value
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1
      canvas.freeDrawingBrush.featureId = feature_id

    }

    function setPreview(item) {
      preview_canvas.clear();
      if (item.connector) {
        preview_canvas.setDimensions({
          height: 50,
          width: 50
        })
        item.strokeWidth = 5
        item.height = 50
        item.width = 50
        item.x1 = 0
        item.x2 = 50
        item.y1 = 0
        item.y2 = 50
      } else {
        item.scaleToWidth(50)
        preview_canvas.setDimensions({
          width: 52,
          height: (item.height * item.scaleY) + 2
        })
      }
      preview_canvas.add(item)
      item.center();
    }

    function resetPreview() {
      preview_canvas.clear()
      preview_canvas.setDimensions({
        height: 50,
        width: 50
      })
      preview_canvas.setZoom(1)
      document.getElementById('img-width-scale').value = 100
      document.getElementById('img-width').innerHTML = 100;
      document.getElementById('drawing-line-value').value = 100
      document.getElementById('drawing-line-width').innerHTML = 100;
    }

    function resetBrush() {
      canvas.freeDrawingBrush = new fabric.PencilBrush(canvas)
      canvas.freeDrawingBrush.color = "black"
      canvas.freeDrawingBrush.width = 1
    }

    function connectPoints(points, itemSelection) {
      var startPoint = points[points.length - 2];
      var endPoint = points[points.length - 1];
      console.log("Connecting: " + startPoint + " & " + endPoint)
      var line = new fabric.Line(
        [
          startPoint.x,
          startPoint.y,
          endPoint.x,
          endPoint.y
        ],
        {
          stroke: itemSelection.stroke,
          strokeDashArray: itemSelection.strokeDashArray,
          strokeLineCap: itemSelection.strokeLineCap,
          strokeLineJoin: itemSelection.strokeLineJoin,
          strokeWidth: parseInt(drawingLineWidthEl.value, 10) || 1,
          hasControls: itemSelection.hasControls,
          lockMovementX: itemSelection.lockMovementX,
          lockMovementY: itemSelection.lockMovementY,
          hoverCursor: itemSelection.hoverCursor,
          originX: itemSelection.originX,
          originY: itemSelection.originY,
          selectable: itemSelection.selectable,
          isActive: true,
          lockScalingX: itemSelection.lockScalingX,
          lockScalingY: itemSelection.lockScalingY,
          selectable: false,
          eventalbe: false,
          featureId: itemSelection.featureId
        }
      )
      lines.push(line);
      canvas.add(line);
    }

    canvas.on('mouse:up', function (opt) {
      if (mapMode == "edit" || mapMode == "draw" || mapMode == "pan") {
        return "don't add anything";
      }
      else if (itemSelection === null) {
        return
      }
      else if (itemSelection.connector) {
        points.push(new fabric.Point(opt.absolutePointer.x, opt.absolutePointer.y));
        if (points.length > 1) {
          connectPoints(points, itemSelection)
        }
      }
      else {
        var cloned_item
        scale = itemScaleEl.value;
        opacity = itemOpacityEl.value;
        itemSelection.clone(function(clone) {
          cloned_item = clone
        });
        cloned_item.setControlsVisibility(itemSelection._controlsVisibility)
        cloned_item.scaleToWidth(scale)
        cloned_item.set({
          left: ( opt.absolutePointer.x - ( (cloned_item.width*cloned_item.scaleX)/2 ) ),
          top: ( opt.absolutePointer.y - ( (cloned_item.height*cloned_item.scaleY)/2 ) ),
          isActive: true,
          opacity: opacity,
          prompt: itemSelection.prompt,
          featureId: itemSelection.featureId,
          mapLegendItemId: itemSelection.mapLegendItemId,
          selectable: false,
          eventable: false,
          fromSVG: itemSelection.fromSVG
        });
        canvas.add(cloned_item);
      }
    });

    // Selection Options
    /////////////////////

    deleteSelectionEl.onclick = function() {
      obj = canvas.getActiveObject()
      if (obj.type == "activeSelection") {
        obj.canvas = canvas
        obj.forEachObject(function(obj) {
          canvas.remove(obj)
        })
        canvas.discardActiveObject();
      } else {
        canvas.remove(obj)
      }
    };

    selectionActiveSettingEl.onclick = function () {
      var obj = canvas.getActiveObject()
      obj.isActive = !obj.isActive
      if (obj.isActive) {
        obj.opacity = 0.8
        canvas.renderAll()
      } else {
        obj.opacity = 0.2
        canvas.renderAll()
      }
      updateSelectionStatus(obj);
    };

    bringToFrontEl.onclick = function () {
      canvas.getActiveObject().bringForward(this, true);
    };

    sendToBackEl.onclick = function () {
      canvas.getActiveObject().sendBackwards(this, true);
    };

    moveToBottomEl.onclick = function () {
      canvas.getActiveObject().sendToBack(this, true);
    };

    itemScaleEl.onchange = function () {
      if (isItemSelected) {
        obj = canvas.getActiveObject();
        obj.scaleToWidth(parseFloat(this.value))
        obj.setCoords();
        canvas.requestRenderAll();
      }
    };

    itemOpacityEl.onchange = function () {
      if (isItemSelected) {
        obj = canvas.getActiveObject();
        obj.opacity = parseFloat(this.value);
        canvas.requestRenderAll();
      } else if (mapMode == "draw"){
        globalOptions.opacity = parseFloat(this.value);
        setDrawingBrush(drawItemId);
      } else {
        if (itemSelection != null){
          obj = itemSelection;
          obj.opacity = parseFloat(this.value);
          setItemSelection(obj);
        }
      } 

    };

    // Legend
    /////////

    // Add Items
    ////////////

    const features = document.getElementsByClassName("feature")
    features.forEach(feature => {
      feature.addEventListener('click', event => {
        const canvas = event.currentTarget.querySelector("canvas")

        jQuery.ajax({
          url: canvas.dataset.url,
          type: "GET",

          dataType: 'JSON',
          success: function ( data, textStatus, jqXHR ) {
            var jsonObj = JSON.parse(data.json)
            fabric.util.enlivenObjects([jsonObj], function(objects) {
              objects.forEach(function(obj) {
                obj.featureId = data["id"]
                globalOptions = data["options"];
                if (data["category"] == "draw") {
                  drawItemId = data["id"];
                  if(data["options"]["opacity"]){
                    itemOpacityEl.value = data["options"]["opacity"];
                    document.getElementById('item-opacity-value').innerHTML = data["options"]["opacity"];
                  } else {
                    itemOpacityEl.value = 0.7;
                    document.getElementById('item-opacity-value').innerHTML = 0.7
                    globalOptions.opacity = 0.7;
                  }
                  setDrawingBrush(data["id"]);
                } else {
                  setItemScale(data.name)
                  //console.log(data)
                  if(data["options"]){
                    if(data["options"]["opacity"] != null){
                      itemOpacityEl.value = data["options"]["opacity"];
                      document.getElementById('item-opacity-value').innerHTML = data["options"]["opacity"];
                      obj.opacity = data["options"]["opacity"];
                    } else {
                      itemOpacityEl.value = 0.7;
                      document.getElementById('item-opacity-value').innerHTML = 0.7;
                      obj.opacity = 0.7;
                    }
                  } else {
                    itemOpacityEl.value = 0.7;
                    document.getElementById('item-opacity-value').innerHTML = 0.7;
                    obj.opacity = 0.7;
                  }
                  setItemSelection(obj);
                }
              })
            })
          }
        })
      })
    })

    lotConstructionStartedItemEl.onclick = function (opt) {
      var circle = new fabric.Circle({
        radius: 10,
        fill: 'rgba(255, 255, 0)',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'left',
        originY: 'top',
        prompt: 'lot',
        mapLegendItemId: 1
      });
      updateItemScale(10);
      updateMapMode("add");
      setItemSelection(circle);

    };
    finalStabilizationItemEl.onclick = function () {
      var circle = new fabric.Circle({
        radius: 10,
        fill: 'rgba(0, 255, 0)',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'left',
        originY: 'top',
        top: 20,
        left: 20,
        prompt: 'lot',
        mapLegendItemId: 2
      });
      updateItemScale(10);
      updateMapMode("add");
      setItemSelection(circle);

      // jQuery.ajax({
      //   url: "/legend_items/2",
      //   type: "GET",
      //   dataType: 'JSON',
      //   success: function ( data, textStatus, jqXHR ) {
      //     var jsonObj = JSON.parse(data.canvas_data_json)
      //     console.log(jsonObj)
      //     fabric.util.enlivenObjects([jsonObj], function(objects) {
      //       objects.forEach(function(obj) {
      //         setItemSelection(obj)
      //       })
      //     })
      //   }
      // })

    };
    concreteWashOutEl.onclick = function () {
      // https://www.sitepoint.com/fabric-js-advanced/
      var text = new fabric.Text('CWO', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var rect = new fabric.Rect({
        width: 35,
        height: 25,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ rect, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 3
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(group);
      // jQuery.ajax({
      //   url: "/template_features",
      //   type: 'POST',
      //   data: {
      //     template_feature: {
      //       template_legend_id: 1,
      //       json: JSON.stringify(group)
      //     }
      //   },
      //   dataType: 'JSON',
      //   success: function ( data, textStatus, jqXHR ) {
      //
      //   }
      // })
    };
    dischargeOutfallEl.onclick = function () {
      // https://www.sitepoint.com/fabric-js-advanced/
      var text = new fabric.Text('OL', {
        fontSize: 12,
        fontWeight: 'bold',
        prompt: 'grading',
        mapLegendItemId: 4
      });
      text.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(text);
    };
    inletProtectionEl.onclick = function () {
      var triangle = new fabric.Triangle({
        top: 0,
        left: 0,
        width: 15,
        height: 15,
        fill: 'blue',
        mapLegendItemId: 5
      });
      triangle.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(triangle);
    };
    rockSockEl.onclick = function () {
      var triangle = new fabric.Triangle({
        top: 0,
        left: 0,
        width: 15,
        height: 15,
        fill: 'brown',
        mapLegendItemId: 6
      });
      triangle.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(triangle);
    };
    spillKitEl.onclick = function () {
      var text = new fabric.Text('SK', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var circle = new fabric.Circle({
        radius: 10,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ circle, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 7
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(group);
    };
    waterSourceEl.onclick = function () {
      var text = new fabric.Text('H2O', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var rect = new fabric.Rect({
        width: 35,
        height: 25,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ rect, text], {
        left: 0,
        top: 0,
        prompt: 'grading',
        mapLegendItemId: 8
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(group);
    };
    portableToiletEl.onclick = function () {
      var text = new fabric.Text('PT', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var rect = new fabric.Rect({
        width: 25,
        height: 25,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ rect, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 9
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(group);
    };
    projectSignEl.onclick = function () {
      var text = new fabric.Text('PS', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var rect = new fabric.Rect({
        width: 25,
        height: 25,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ rect, text], {
        left: 0,
        top: 0,
        prompt: 'grading',
        mapLegendItemId: 10
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(group);
    };
    aboveGroundStorageTankEl.onclick = function () {
      var text = new fabric.Text('AST', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var rect = new fabric.Rect({
        width: 35,
        height: 25,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ rect, text], {
        left: 0,
        top: 0,
        prompt: 'grading',
        mapLegendItemId: 11
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(group);
    };
    storageAreaEl.onclick = function () {
      var text = new fabric.Text('SA', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var rect = new fabric.Rect({
        width: 25,
        height: 25,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ rect, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 12
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(group);
    };
    floatingTurbidityBarrierEl.onclick = function () {
      var text = new fabric.Text('TB', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var rect = new fabric.Rect({
        width: 25,
        height: 25,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ rect, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 13
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(group);
    };
    sedimentBasinEl.onclick = function () {
      var text = new fabric.Text('SB', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var rect = new fabric.Rect({
        width: 25,
        height: 25,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ rect, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 14
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(group);
    };
    constructionStagingAreaEl.onclick = function () {
      var text = new fabric.Text('CSA', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var rect = new fabric.Rect({
        width: 35,
        height: 25,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ rect, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 15
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(group);
    };
    stormwaterManagementFacilityEl.onclick = function () {
      var text = new fabric.Text('SMF', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var rect = new fabric.Rect({
        width: 35,
        height: 25,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ rect, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 16
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(group);
    };
    stockpileEl.onclick = function () {
      var text = new fabric.Text('SP', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var rect = new fabric.Rect({
        width: 25,
        height: 25,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ rect, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 17
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(group);
    };
    dustControlEl.onclick = function () {
      var text = new fabric.Text('DC', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var rect = new fabric.Rect({
        width: 25,
        height: 25,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ rect, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 18
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(group);
    };
    trashContainerEl.onclick = function () {
      var text = new fabric.Text('TC', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var rect = new fabric.Rect({
        width: 25,
        height: 25,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ rect, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 19
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(group);
    };
    dewateringEl.onclick = function () {
      var text = new fabric.Text('DW', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var rect = new fabric.Rect({
        width: 25,
        height: 25,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ rect, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 20
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(group);
    };
    directionOfFlowEl.onclick = function () {
      var line = new fabric.Line( [10,10,50,50], {
        stroke: 'black',
        strokeWidth: 1.5,
        originX: 'center',
        originY: 'center'
      });
      var arrowhead = new fabric.Triangle({
        top: 10,
        left: 10,
        angle: -45,
        width: 15,
        height: 15,
        fill: 'black',
        originX: 'center',
        originY: 'center'
      })
      var group = new fabric.Group([line, arrowhead], {
        top: 0,
        left: 0,
        mapLegendItemId: 21
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(group);
    };
    triangularFilterDikeEl.onclick = function () {
      var triangle = new fabric.Triangle({
        top: 0,
        left: 0,
        width: 15,
        height: 15,
        fill: 'black',
        mapLegendItemId: 22
      });
      triangle.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(triangle);
    };
    lotRemovedIconEl.onclick = function () {
      var text = new fabric.Text('X', {
        fontSize: 12,
        fontWeight: 'bold',
        fill: 'red',
        originX: 'left',
        originY: 'top',
        mapLegendItemId: 24
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(text);
    };
    swpppEl.onclick = function () {
      var text = new fabric.Text('SWPPP', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var rect = new fabric.Rect({
        width: 45,
        height: 25,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ rect, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 25
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(group);
    };
    checkDamIconEl.onclick = function () {
      var text = new fabric.Text('CD', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var rect = new fabric.Rect({
        width: 25,
        height: 25,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ rect, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 26
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(group);
    };
    receivingWatersIconEl.onclick = function () {
      var text = new fabric.Text('RW', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var rect = new fabric.Rect({
        width: 25,
        height: 25,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ rect, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 27
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(group);
    };
    temporaryOutletProtectionIconEl.onclick = function () {
      var triangle = new fabric.Triangle({
        top: 0,
        left: 0,
        width: 15,
        height: 15,
        fill: 'rgba(173, 216, 230, 0.8)',
        mapLegendItemId: 28
      });
      triangle.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateItemScale(30);
      updateMapMode("add");
      setItemSelection(triangle);
    };
    blueTextBoxEl.onclick = function () {
      var bluetextbox = new fabric.Textbox('Enter text...',{
        left: 50,
        top: 50,
        width: 100,
        fontSize: 18,
        backgroundColor: 'rgba(255,255,255,0.8)',
        fill: "rgb(0,0,255)",
        mapLegendItemId: 29
      });
      bluetextbox.setControlsVisibility({
        mt: false,
        mb: false
      })
      canvas.add(bluetextbox);
      updateMapMode("edit");
    };
    redTextBoxEl.onclick = function () {
      var redtextbox = new fabric.Textbox('Enter text...',{
        left: 50,
        top: 50,
        width: 100,
        fontSize: 18,
        backgroundColor: 'rgba(255,255,255,0.8)',
        fill: "rgb(255,0,0)",
        mapLegendItemId: 69
      });
      redtextbox.setControlsVisibility({
        mt: false,
        mb: false
      })
      canvas.add(redtextbox);
      updateMapMode("edit");
    };
    northArrowEl.onclick = function () {
      // http://www.independent-software.com/loading-an-svg-image-with-fabric-js.html
      fabric.loadSVGFromURL('https://erxstorage.s3-us-west-1.amazonaws.com/images/map_legend/north-arrow-2.svg', function(objects, options) {
        var obj = fabric.util.groupSVGElements(objects, options);
        obj.scaleToWidth(100);
        obj.scaleToHeight(100);
        obj.mapLegendItemId = 30;
        updateItemScale(40);
        updateMapMode("add");
        setItemSelection(obj);
      });
    };
    surfaceWaterEl.onclick = function () {
      // http://www.independent-software.com/loading-an-svg-image-with-fabric-js.html
      fabric.loadSVGFromURL('https://erxstorage.s3-us-west-1.amazonaws.com/images/map_legend/water-solid.svg', function(objects, options) {
        var obj = fabric.util.groupSVGElements(objects, options);
        obj.scaleToWidth(50);
        obj.scaleToHeight(50);
        obj.mapLegendItemId = 31;
        updateItemScale(20);
        updateMapMode("add");
        setItemSelection(obj);
      });
    };
    notOursEl.onclick = function () {
      // http://www.independent-software.com/loading-an-svg-image-with-fabric-js.html
      fabric.loadSVGFromURL('https://erxstorage.s3.us-west-1.amazonaws.com/images/ban-solid.svg', function(objects, options) {
        var obj = fabric.util.groupSVGElements(objects, options);
        obj.scaleToWidth(50);
        obj.scaleToHeight(50);
        obj.fill = 'red';
        updateItemScale(20);
        updateMapMode("add");
        setItemSelection(obj);
      });
    };
    mixingStationEl.onclick = function () {
      // http://www.independent-software.com/loading-an-svg-image-with-fabric-js.html
      fabric.loadSVGFromURL('https://erxstorage.s3-us-west-1.amazonaws.com/images/map_legend/concrete_mixer.svg', function(objects, options) {
        var obj = fabric.util.groupSVGElements(objects, options);
        obj.scaleToWidth(50);
        obj.scaleToHeight(50);
        updateMapMode("add");
        var text = new fabric.Text('Mixing Station', {
          fontSize: 12,
          fontWeight: 'bold',
          originX: 'center',
          originY: 'center',
          top: 60,
          left: 42
        });
        var rect = new fabric.Rect({
          width: (obj.width*obj.scaleX) + 10,
          height: (obj.height*obj.scaleY) + text.height + 10,
          fill: 'white',
          stroke: 'black',
          strokeWidth: 2,
          originX: 'center',
          originY: 'center',
          top: (obj.height*obj.scaleY + text.height)/2,
          left: (obj.width*obj.scaleX)/2
        });
        var group = new fabric.Group([rect, text, obj], {
          left: 0,
          top: 0,
          mapLegendItemId: 32
        });
        group.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false
        });
        updateItemScale(30);
        setItemSelection(group);
      });
    };
    wetlandEl.onclick = function () {
      var text = new fabric.Text('WL', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var circle = new fabric.Circle({
        radius: 12,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ circle, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 33
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateMapMode("add");
      setItemSelection(group);
    };
    drywellEl.onclick = function () {
      var text = new fabric.Text('DW', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var circle = new fabric.Circle({
        radius: 12,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ circle, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 34
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateMapMode("add");
      setItemSelection(group);
    };
    sinkholeEl.onclick = function () {
      var text = new fabric.Text('S/S', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var circle = new fabric.Circle({
        radius: 12,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ circle, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 35
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateMapMode("add");
      setItemSelection(group);
    };
    lotAccessWattleEl.onclick = function () {
      var text = new fabric.Text('W', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var circle = new fabric.Circle({
        radius: 12,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ circle, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 36
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateMapMode("add");
      setItemSelection(group);
    };
    lotAccessCurlexEl.onclick = function () {
      var text = new fabric.Text('X', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var circle = new fabric.Circle({
        radius: 12,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ circle, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 37
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateMapMode("add");
      setItemSelection(group);
    };
    lotAccessCutbackEl.onclick = function () {
      var text = new fabric.Text('C', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var circle = new fabric.Circle({
        radius: 12,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ circle, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 38
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateMapMode("add");
      setItemSelection(group);
    };
    lotAccessRockpadEl.onclick = function () {
      var text = new fabric.Text('R', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var circle = new fabric.Circle({
        radius: 12,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ circle, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 39
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateMapMode("add");
      setItemSelection(group);
    };
    lotAccessMulchEl.onclick = function () {
      var text = new fabric.Text('M', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var circle = new fabric.Circle({
        radius: 12,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ circle, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 40
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateMapMode("add");
      setItemSelection(group);
    };
    lotAccessTrackoutEl.onclick = function () {
      var text = new fabric.Text('T', {
        fontSize: 12,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center'
      });
      var circle = new fabric.Circle({
        radius: 12,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center'
      });
      var group = new fabric.Group([ circle, text], {
        left: 0,
        top: 0,
        mapLegendItemId: 41
      });
      group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });
      updateMapMode("add");
      setItemSelection(group);
    };
    eraseBoxEl.onclick = function () {
      var rect = new fabric.Rect({
        width: 50,
        height: 25,
        fill: 'white',
        stroke: 'white',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center',
        mapLegendItemId: 42
      });
      updateMapMode("add");
      setItemSelection(rect);
    };

    // Drawing Options
    //////////////////

    drawingLineWidthEl.onchange = function() {
      canvas.freeDrawingBrush.width = parseInt(this.value, 10) || 1;
      this.previousSibling.innerHTML = this.value;
    };

    lotConstructionStartedEl.onclick = function () {
      var initialZoom = 1;
      var color = 'rgba(255, 255, 0, 0.7)' // yellow solid
      var obj = new fabric.Rect({
        height: 50,
        width: 50,
        fill: color
      })
      resetPreview();
      patternSourceCanvas = createDrawCanvas({
        height: 50 * initialZoom,
        width: 50 * initialZoom,
        obj: obj,
        initialZoom: initialZoom,
        color: color,
        mapLegendItemId: 43
      });
      canvas.freeDrawingBrush = new fabric['PencilBrush'](canvas);
      canvas.freeDrawingBrush.color = color;
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      canvas.freeDrawingBrush.mapLegendItemId = 43;
    };
    finalStabilizationEl.onclick = function () {
      var initialZoom = 1;
      var color = 'rgba(0, 255, 0, 0.5)'; // green solid
      var obj = new fabric.Rect({
        height: 50,
        width: 50,
        fill: color
      })
      resetPreview();
      patternSourceCanvas = createDrawCanvas({
        height: 50 * initialZoom,
        width: 50 * initialZoom,
        obj: obj,
        initialZoom: initialZoom,
        color: color,
        mapLegendItemId: 44
      });
      canvas.freeDrawingBrush = new fabric['PencilBrush'](canvas);
      canvas.freeDrawingBrush.color = color;
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      canvas.freeDrawingBrush.mapLegendItemId = 44;
    };

    constructionEntranceBmpEl.onclick = function () {
      var texturePatternBrush = new fabric['PencilBrush'](canvas);
      var initialZoom = 1;
      var color = 'rgba(255, 165, 0, 0.5)' // orange solid
      var obj = new fabric.Rect({
        height: 50,
        width: 50,
        fill: color
      })
      resetPreview();
      patternSourceCanvas = createDrawCanvas({
        height: 50 * initialZoom,
        width: 50 * initialZoom,
        obj: obj,
        initialZoom: initialZoom,
        color: color,
        mapLegendItemId: 45
      });
      canvas.freeDrawingBrush = new fabric['PencilBrush'](canvas);
      canvas.freeDrawingBrush.color = color;
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      canvas.freeDrawingBrush.mapLegendItemId = 45;
    };
    temporaryStabilizationEl.onclick = function () {
      var constructionEntrancePatternBrush = new fabric.PatternBrush(canvas);

      constructionEntrancePatternBrush.getPatternSrc = function() {
        var patternSourceCanvas = fabric.document.createElement('canvas');
        patternSourceCanvas.width = patternSourceCanvas.height = 10;
        var ctx = patternSourceCanvas.getContext('2d');
        ctx.strokeStyle = 'rgba(255, 235, 0, 0.7)';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(0,5);
        ctx.lineTo(5, 0);
        ctx.moveTo(5,10);
        ctx.lineTo(10,5);
        ctx.closePath();
        ctx.stroke();
        return patternSourceCanvas;
      };
      resetPreview();
      fabric.Image.fromURL("https://erxstorage.s3-us-west-1.amazonaws.com/images/map_legend/temporary_stabilization.png", function(img) {
       img.set({
         left: 0,
         top: 0,
         width:50,
         height:50
       });
       preview_canvas.add(img);
      });

      drawingLineWidthEl.value = 25,
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
      canvas.freeDrawingBrush = constructionEntrancePatternBrush;
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      canvas.freeDrawingBrush.mapLegendItemId = 46;

    };

    siltFenceDrawEl.onclick = function () {
      canvas.freeDrawingBrush = new fabric['PencilBrush'](canvas);
      // red line
      canvas.freeDrawingBrush.color = 'rgb(255, 0, 0, 0.8)';
      drawingLineWidthEl.value = 3,
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      canvas.freeDrawingBrush.mapLegendItemId = 47;

      var line = new fabric.Line([0, 0, 25, 25], {
        fill: canvas.freeDrawingBrush.color,
        stroke: canvas.freeDrawingBrush.color,
        strokeWidth: canvas.freeDrawingBrush.width,
        strokeDashArray: canvas.freeDrawingBrush.strokeDashArray,
        strokeLineCap: canvas.freeDrawingBrush.strokeLineCap
      })
      setPreview(line)
    };
    siltFenceLineEl.onclick = function () {
      points = [];
      lines = [];
      drawingLineWidthEl.value = 3;
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value;
      var line = new fabric.Line(
        [
        ],
        {
          stroke: "rgb(255, 0, 0, 0.8)",
          hasControls: true,
          hoverCursor: "default",
          originX: "center",
          originY: "center",
          selectable: true,
          connector: true,
          lockScalingX: true,
          lockScalingY: true,
          mapLegendItemId: 47,
          isActive: true
        }
      );
      updateMapMode("add");
      showDrawingSection();
      hideItemOpacitySection();
      hideItemScaleSection();
      setItemSelection(line);
    };

    wireSiltFenceDrawEl.onclick = function () {
      canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
      // safety orange
      canvas.freeDrawingBrush.color = 'rgb(255, 0, 0, 0.8)';
      drawingLineWidthEl.value = 3,
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      canvas.freeDrawingBrush.strokeDashArray = [5,10];
      canvas.freeDrawingBrush.strokeLineCap = 'round';
      canvas.freeDrawingBrush.mapLegendItemId = 48;
      var line = new fabric.Line([0, 0, 25, 25], {
        fill: canvas.freeDrawingBrush.color,
        stroke: canvas.freeDrawingBrush.color,
        strokeWidth: canvas.freeDrawingBrush.width,
        strokeDashArray: canvas.freeDrawingBrush.strokeDashArray,
        strokeLineCap: canvas.freeDrawingBrush.strokeLineCap
      })
      setPreview(line)
    };
    wireSiltFenceLineEl.onclick = function () {
      points = [];
      lines = [];
      drawingLineWidthEl.value = 3;
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value;
      var line = new fabric.Line(
        [
        ],
        {
          stroke: "rgb(255, 0, 0, 0.8)",
          strokeDashArray: [5, 10],
          strokeLineCap: 'round',
          strokeLineJoin: 'bevel',
          hasControls: true,
          hoverCursor: "default",
          originX: "center",
          originY: "center",
          selectable: true,
          connector: true,
          lockScalingX: true,
          lockScalingY: true,
          mapLegendItemId: 48
        }
      );
      updateMapMode("add");
      showDrawingSection();
      hideItemOpacitySection();
      hideItemScaleSection();
      setItemSelection(line);
    };

    strawWattleDrawEl.onclick = function () {
      canvas.freeDrawingBrush = new fabric['PencilBrush'](canvas);
      // blue line
      canvas.freeDrawingBrush.color = 'rgb(0, 0, 255, 0.8)';
      drawingLineWidthEl.value = 3,
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      canvas.freeDrawingBrush.mapLegendItemId = 49;
      var line = new fabric.Line([0, 0, 25, 25], {
        fill: canvas.freeDrawingBrush.color,
        stroke: canvas.freeDrawingBrush.color,
        strokeWidth: canvas.freeDrawingBrush.width,
        strokeDashArray: canvas.freeDrawingBrush.strokeDashArray,
        strokeLineCap: canvas.freeDrawingBrush.strokeLineCap
      })
      setPreview(line)
    };
    strawWattleLineEl.onclick = function () {
      points = [];
      lines = [];
      drawingLineWidthEl.value = 3;
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value;
      var line = new fabric.Line(
        [
        ],
        {
          stroke: "rgb(0, 0, 255, 0.8)",
          hasControls: true,
          hoverCursor: "default",
          originX: "center",
          originY: "center",
          selectable: true,
          connector: true,
          lockScalingX: true,
          lockScalingY: true,
          mapLegendItemId: 49
        }
      );
      updateMapMode("add");
      showDrawingSection();
      hideItemOpacitySection();
      hideItemScaleSection();
      setItemSelection(line);
    };
    heavyweightWattleDrawEl.onclick = function () {
      canvas.freeDrawingBrush = new fabric['PencilBrush'](canvas);
      // teal line
      canvas.freeDrawingBrush.color = 'rgb(0, 128, 128, 0.8)';
      drawingLineWidthEl.value = 3,
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      canvas.freeDrawingBrush.mapLegendItemId = 50;
      var line = new fabric.Line([0, 0, 25, 25], {
        fill: canvas.freeDrawingBrush.color,
        stroke: canvas.freeDrawingBrush.color,
        strokeWidth: canvas.freeDrawingBrush.width,
        strokeDashArray: canvas.freeDrawingBrush.strokeDashArray,
        strokeLineCap: canvas.freeDrawingBrush.strokeLineCap
      })
      setPreview(line)
    };
    heavyweightWattleLineEl.onclick = function () {
      points = [];
      lines = [];
      drawingLineWidthEl.value = 3;
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value;
      var line = new fabric.Line(
        [
        ],
        {
          stroke: "rgb(0, 128, 128, 0.8)",
          hasControls: true,
          hoverCursor: "default",
          originX: "center",
          originY: "center",
          selectable: true,
          connector: true,
          lockScalingX: true,
          lockScalingY: true,
          mapLegendItemId: 50
        }
      );
      updateMapMode("add");
      showDrawingSection();
      hideItemOpacitySection();
      hideItemScaleSection();
      setItemSelection(line);
    };
    perimeterWallDrawEl.onclick = function () {
      canvas.freeDrawingBrush = new fabric['PencilBrush'](canvas);
      // black line
      canvas.freeDrawingBrush.color = 'rgb(0, 0, 0, 0.8)';
      drawingLineWidthEl.value = 3,
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      canvas.freeDrawingBrush.mapLegendItemId = 51;
      var line = new fabric.Line([0, 0, 25, 25], {
        fill: canvas.freeDrawingBrush.color,
        stroke: canvas.freeDrawingBrush.color,
        strokeWidth: canvas.freeDrawingBrush.width,
        strokeDashArray: canvas.freeDrawingBrush.strokeDashArray,
        strokeLineCap: canvas.freeDrawingBrush.strokeLineCap
      })
      setPreview(line)
    };

    perimeterWallLineEl.onclick = function () {
      points = [];
      lines = [];
      drawingLineWidthEl.value = 3;
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value;
      var line = new fabric.Line(
        [
        ],
        {
          stroke: "rgb(0, 0, 0, 0.8)",
          hasControls: true,
          hoverCursor: "default",
          originX: "center",
          originY: "center",
          selectable: true,
          connector: true,
          lockScalingX: true,
          lockScalingY: true,
          mapLegendItemId: 51
        }
      );
      updateMapMode("add");
      showDrawingSection();
      hideItemOpacitySection();
      hideItemScaleSection();
      setItemSelection(line);
    };
    lotBmpDrawEl.onclick = function () {
      canvas.freeDrawingBrush = new fabric['PencilBrush'](canvas);
      // orange line
      canvas.freeDrawingBrush.color = 'rgb(255, 165, 0, 0.8)';
      drawingLineWidthEl.value = 3,
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      canvas.freeDrawingBrush.mapLegendItemId = 52;
      var line = new fabric.Line([0, 0, 25, 25], {
        fill: canvas.freeDrawingBrush.color,
        stroke: canvas.freeDrawingBrush.color,
        strokeWidth: canvas.freeDrawingBrush.width,
        strokeDashArray: canvas.freeDrawingBrush.strokeDashArray,
        strokeLineCap: canvas.freeDrawingBrush.strokeLineCap
      })
      setPreview(line)
    };
    lotBmpLineEl.onclick = function () {
      points = [];
      lines = [];
      drawingLineWidthEl.value = 3;
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value;
      var line = new fabric.Line(
        [
        ],
        {
          stroke: "rgb(255, 165, 0, 0.8)",
          hasControls: true,
          hoverCursor: "default",
          originX: "center",
          originY: "center",
          selectable: true,
          connector: true,
          lockScalingX: true,
          lockScalingY: true,
          mapLegendItemId: 52
        }
      );
      updateMapMode("add");
      showDrawingSection();
      hideItemOpacitySection();
      hideItemScaleSection();
      setItemSelection(line);
    };
    curbCutbackDrawEl.onclick = function () {
      canvas.freeDrawingBrush = new fabric['PencilBrush'](canvas);
      // pink line
      canvas.freeDrawingBrush.color = 'rgb(255, 20, 147, 0.8)';
      drawingLineWidthEl.value = 3,
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      canvas.freeDrawingBrush.mapLegendItemId = 53;
      var line = new fabric.Line([0, 0, 25, 25], {
        fill: canvas.freeDrawingBrush.color,
        stroke: canvas.freeDrawingBrush.color,
        strokeWidth: canvas.freeDrawingBrush.width,
        strokeDashArray: canvas.freeDrawingBrush.strokeDashArray,
        strokeLineCap: canvas.freeDrawingBrush.strokeLineCap
      })
      setPreview(line)
    };
    curbCutbackLineEl.onclick = function () {
      points = [];
      lines = [];
      drawingLineWidthEl.value = 3;
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value;
      var line = new fabric.Line(
        [
        ],
        {
          stroke: "rgb(255, 20, 147, 0.8)",
          hasControls: true,
          hoverCursor: "default",
          originX: "center",
          originY: "center",
          selectable: true,
          connector: true,
          lockScalingX: true,
          lockScalingY: true,
          mapLegendItemId: 53
        }
      );
      updateMapMode("add");
      showDrawingSection();
      hideItemOpacitySection();
      hideItemScaleSection();
      setItemSelection(line);
    };
    rockBermDrawEl.onclick = function () {
      canvas.freeDrawingBrush = new fabric['PencilBrush'](canvas);
      // purple line
      canvas.freeDrawingBrush.color = 'rgb(128, 0, 128, 0.8)';
      drawingLineWidthEl.value = 3,
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      canvas.freeDrawingBrush.mapLegendItemId = 54;
      var line = new fabric.Line([0, 0, 25, 25], {
        fill: canvas.freeDrawingBrush.color,
        stroke: canvas.freeDrawingBrush.color,
        strokeWidth: canvas.freeDrawingBrush.width,
        strokeDashArray: canvas.freeDrawingBrush.strokeDashArray,
        strokeLineCap: canvas.freeDrawingBrush.strokeLineCap
      })
      setPreview(line)
    };
    rockBermLineEl.onclick = function () {
      points = [];
      lines = [];
      drawingLineWidthEl.value = 3;
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value;
      var line = new fabric.Line(
        [
        ],
        {
          stroke: "rgb(128, 0, 128, 0.8)",
          hasControls: true,
          hoverCursor: "default",
          originX: "center",
          originY: "center",
          selectable: true,
          connector: true,
          lockScalingX: true,
          lockScalingY: true,
          mapLegendItemId: 54
        }
      );
      updateMapMode("add");
      showDrawingSection();
      hideItemOpacitySection();
      hideItemScaleSection();
      setItemSelection(line);
    };
    constructionFenceDrawEl.onclick = function () {
      canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
      // safety orange
      canvas.freeDrawingBrush.color = 'rgb(255, 103, 0, 0.8)';
      drawingLineWidthEl.value = 3,
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      canvas.freeDrawingBrush.strokeDashArray = [5,10];
      canvas.freeDrawingBrush.strokeLineCap = 'square';
      canvas.freeDrawingBrush.mapLegendItemId = 55;
      var line = new fabric.Line([0, 0, 25, 25], {
        fill: canvas.freeDrawingBrush.color,
        stroke: canvas.freeDrawingBrush.color,
        strokeWidth: canvas.freeDrawingBrush.width,
        strokeDashArray: canvas.freeDrawingBrush.strokeDashArray,
        strokeLineCap: canvas.freeDrawingBrush.strokeLineCap
      })
      setPreview(line)
    };
    constructionFenceLineEl.onclick = function () {
      points = [];
      lines = [];
      drawingLineWidthEl.value = 3;
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value;
      var line = new fabric.Line(
        [
        ],
        {
          stroke: "rgb(255, 103, 0, 0.8)",
          strokeDashArray: [10, 5],
          hasControls: true,
          hoverCursor: "default",
          originX: "center",
          originY: "center",
          selectable: true,
          connector: true,
          lockScalingX: true,
          lockScalingY: true,
          mapLegendItemId: 55
        }
      );
      updateMapMode("add");
      showDrawingSection();
      hideItemOpacitySection();
      hideItemScaleSection();
      setItemSelection(line);
    };
    diversionDitchLineEl.onclick = function () {
      points = [];
      lines = [];
      drawingLineWidthEl.value = 3;
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value;
      var line = new fabric.Line(
        [
        ],
        {
          stroke: "rgb(100, 255, 0, 0.8)",
          hasControls: true,
          hoverCursor: "default",
          originX: "center",
          originY: "center",
          selectable: true,
          connector: true,
          lockScalingX: true,
          lockScalingY: true,
          mapLegendItemId: 56
        }
      );
      updateMapMode("add");
      showDrawingSection();
      hideItemOpacitySection();
      hideItemScaleSection();
      setItemSelection(line);
    };
    ripRapDrawEl.onclick = function () {
      var texturePatternBrush = new fabric.PatternBrush(canvas);
      var initialZoom = 0.35;
      var color = 'rgba(50, 50, 50, 0.7)';
      var obj = new fabric.Rect({
        height: 25,
        width: 25,
        fill: color
      })
      resetPreview();
      updateMapMode("draw");
      patternSourceCanvas = createDrawCanvas({
        height: 50 * initialZoom,
        width: 50 * initialZoom,
        obj: obj,
        initialZoom: initialZoom,
        color: color,
        mapLegendItemId: 57
      });
      texturePatternBrush.source = patternSourceCanvas.getElement();
      canvas.freeDrawingBrush = texturePatternBrush;
      drawingLineWidthEl.value = 25,
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      updateDrawCanvases(patternSourceCanvas, obj, canvas.freeDrawingBrush.mapLegendItemId);
    };

    hydroseedDrawEl.onclick = function () {
      var texturePatternBrush = new fabric.PatternBrush(canvas);
      var initialZoom = 0.35;
      var color = 'rgba(0, 0, 255, 0.7)';
      var obj = new fabric.Rect({
        height: 25,
        width: 25,
        fill: color
      })
      resetPreview();
      updateMapMode("draw");
      patternSourceCanvas = createDrawCanvas({
        height: 50 * initialZoom,
        width: 50 * initialZoom,
        obj: obj,
        initialZoom: initialZoom,
        color: color,
        mapLegendItemId: 58
      });
      texturePatternBrush.source = patternSourceCanvas.getElement();
      canvas.freeDrawingBrush = texturePatternBrush;
      drawingLineWidthEl.value = 25,
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      updateDrawCanvases(patternSourceCanvas, obj, canvas.freeDrawingBrush.mapLegendItemId);
    };
    geotextileDrawEl.onclick = function () {
      var texturePatternBrush = new fabric.PatternBrush(canvas);
      var initialZoom = 0.35;
      var color = 'rgba(173, 255, 47, 0.8)';
      var obj = new fabric.Rect({
        height: 25,
        width: 25,
        fill: color
      })
      resetPreview();
      updateMapMode("draw");
      patternSourceCanvas = createDrawCanvas({
        height: 50 * initialZoom,
        width: 50 * initialZoom,
        obj: obj,
        initialZoom: initialZoom,
        color: color,
        mapLegendItemId: 59
      });
      texturePatternBrush.source = patternSourceCanvas.getElement();
      canvas.freeDrawingBrush = texturePatternBrush;
      drawingLineWidthEl.value = 25,
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      updateDrawCanvases(patternSourceCanvas, obj, canvas.freeDrawingBrush.mapLegendItemId);
    };
    mulchDrawEl.onclick = function () {
      var texturePatternBrush = new fabric.PatternBrush(canvas);
      var initialZoom = 0.35;
      var color = 'rgba(139, 69, 19, 0.7)';
      var obj = new fabric.Rect({
        height: 25,
        width: 25,
        fill: color
      })
      resetPreview();
      updateMapMode("draw");
      patternSourceCanvas = createDrawCanvas({
        height: 50 * initialZoom,
        width: 50 * initialZoom,
        obj: obj,
        initialZoom: initialZoom,
        color: color,
        mapLegendItemId: 60
      });
      texturePatternBrush.source = patternSourceCanvas.getElement();
      canvas.freeDrawingBrush = texturePatternBrush;
      drawingLineWidthEl.value = 25,
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      updateDrawCanvases(patternSourceCanvas, obj, canvas.freeDrawingBrush.mapLegendItemId);
    };
    erosionControlBlanketDrawEl.onclick = function () {
      var texturePatternBrush = new fabric.PatternBrush(canvas);
      var initialZoom = 0.35;
      var color = 'rgba(255, 0, 0, 0.7)';
      var obj = new fabric.Rect({
        height: 25,
        width: 25,
        fill: color
      })
      resetPreview();
      updateMapMode("draw");
      patternSourceCanvas = createDrawCanvas({
        height: 50 * initialZoom,
        width: 50 * initialZoom,
        obj: obj,
        initialZoom: initialZoom,
        color: color,
        mapLegendItemId: 61
      });
      texturePatternBrush.source = patternSourceCanvas.getElement();
      canvas.freeDrawingBrush = texturePatternBrush;
      drawingLineWidthEl.value = 25,
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      updateDrawCanvases(patternSourceCanvas, obj, canvas.freeDrawingBrush.mapLegendItemId);
    };
    seedCrimpMulchDrawEl.onclick = function () {
      var texturePatternBrush = new fabric.PatternBrush(canvas);
      var initialZoom = 0.35;
      var color = 'rgba(0, 255, 255, 0.8)';
      var obj = new fabric.Rect({
        height: 25,
        width: 25,
        fill: color
      })
      resetPreview();
      updateMapMode("draw");
      patternSourceCanvas = createDrawCanvas({
        height: 50 * initialZoom,
        width: 50 * initialZoom,
        obj: obj,
        initialZoom: initialZoom,
        color: color,
        mapLegendItemId: 62
      });
      texturePatternBrush.source = patternSourceCanvas.getElement();
      canvas.freeDrawingBrush = texturePatternBrush;
      drawingLineWidthEl.value = 25,
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      updateDrawCanvases(patternSourceCanvas, obj, canvas.freeDrawingBrush.mapLegendItemId);
    };
    surfaceRougheningDrawEl.onclick = function () {
      var texturePatternBrush = new fabric.PatternBrush(canvas);
      var initialZoom = 0.35;
      var color = 'rgba(186, 184, 108, 0.8)'
      var obj = new fabric.Rect({
        height: 25,
        width: 25,
        fill: color
      })
      resetPreview();
      updateMapMode("draw");
      patternSourceCanvas = createDrawCanvas({
        height: 50 * initialZoom,
        width: 50 * initialZoom,
        obj: obj,
        initialZoom: initialZoom,
        color: color,
        mapLegendItemId: 63
      });
      texturePatternBrush.source = patternSourceCanvas.getElement();
      canvas.freeDrawingBrush = texturePatternBrush;
      drawingLineWidthEl.value = 25,
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      updateDrawCanvases(patternSourceCanvas, obj, canvas.freeDrawingBrush.mapLegendItemId);
    };
    vegetativeBufferDrawEl.onclick = function () {
      // pistacio green squares area https://www.rapidtables.com/web/color/green-color.html
      var texturePatternBrush = new fabric.PatternBrush(canvas);
      var initialZoom = 0.35;
      var color = 'rgba(174, 219, 159, 0.8)' // gray solid
      var obj = new fabric.Rect({
        height: 25,
        width: 25,
        fill: color
      })
      resetPreview();
      updateMapMode("draw");
      patternSourceCanvas = createDrawCanvas({
        height: 50 * initialZoom,
        width: 50 * initialZoom,
        obj: obj,
        initialZoom: initialZoom,
        color: color,
        mapLegendItemId: 69
      });
      texturePatternBrush.source = patternSourceCanvas.getElement();
      canvas.freeDrawingBrush = texturePatternBrush;
      drawingLineWidthEl.value = 25,
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      updateDrawCanvases(patternSourceCanvas, obj, canvas.freeDrawingBrush.mapLegendItemId);
    };
    lotAccessBmpDrawEl.onclick = function () {
      var initialZoom = 1;
      var color = 'rgba(113, 126, 142, 0.5)' // gray solid
      var obj = new fabric.Rect({
        height: 50,
        width: 50,
        fill: color
      })
      resetPreview();
      updateMapMode("draw");
      patternSourceCanvas = createDrawCanvas({
        height: 50 * initialZoom,
        width: 50 * initialZoom,
        obj: obj,
        initialZoom: initialZoom,
        color: color,
        mapLegendItemId: 64
      });
      canvas.freeDrawingBrush = new fabric['PencilBrush'](canvas);
      canvas.freeDrawingBrush.color = color;
      drawingLineWidthEl.value = 25,
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      canvas.freeDrawingBrush.mapLegendItemId = 64;
    };
    turbidityCurtainLineEl.onclick = function () {
      points = [];
      lines = [];
      drawingLineWidthEl.value = 3;
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value;
      var line = new fabric.Line(
        [
        ],
        {
          stroke: "rgb(64, 224, 208, 0.8)",
          hasControls: true,
          hoverCursor: "default",
          originX: "center",
          originY: "center",
          selectable: true,
          connector: true,
          lockScalingX: true,
          lockScalingY: true,
          mapLegendItemId: 65
        }
      );
      updateMapMode("add");
      showDrawingSection();
      hideItemOpacitySection();
      hideItemScaleSection();
      setItemSelection(line);
    };
    turbidityCurtainDrawEl.onclick = function () {
      canvas.freeDrawingBrush = new fabric['PencilBrush'](canvas);
      // turquoise line
      canvas.freeDrawingBrush.color = 'rgb(64, 224, 208, 0.8)';
      drawingLineWidthEl.value = 3,
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      canvas.freeDrawingBrush.mapLegendItemId = 65;
      var line = new fabric.Line([0, 0, 25, 25], {
        fill: canvas.freeDrawingBrush.color,
        stroke: canvas.freeDrawingBrush.color,
        strokeWidth: canvas.freeDrawingBrush.width,
        strokeDashArray: canvas.freeDrawingBrush.strokeDashArray,
        strokeLineCap: canvas.freeDrawingBrush.strokeLineCap
      })
      setPreview(line)
    };
    disturbedSoilEl.onclick = function () {
      var constructionEntrancePatternBrush = new fabric.PatternBrush(canvas);

      constructionEntrancePatternBrush.getPatternSrc = function() {
        var patternCanvas = fabric.document.createElement('canvas');
        patternCanvas.width = patternCanvas.height = 10;
        var ctx = patternCanvas.getContext('2d');
        ctx.strokeStyle = 'rgba(139, 69, 19, 0.7)';
        ctx.lineWidth = 2.5;
        ctx.beginPath();
        ctx.moveTo(0,5);
        ctx.lineTo(5, 0);
        ctx.moveTo(5,10);
        ctx.lineTo(10,5);
        ctx.closePath();
        ctx.stroke();
        return patternCanvas;
      };
      resetPreview();
      updateMapMode("draw");
      fabric.Image.fromURL("https://erxstorage.s3-us-west-1.amazonaws.com/images/map_legend/disturbed_soil.png", function(img) {
       img.set({
         left: 0,
         top: 0,
         width:50,
         height:50
       });
       preview_canvas.add(img);
      });
      canvas.freeDrawingBrush = constructionEntrancePatternBrush;
      drawingLineWidthEl.value = 25,
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      canvas.freeDrawingBrush.mapLegendItemId = 66;
    };
    eraseDrawEl.onclick = function () {
      var initialZoom = 1;
      var color = 'rgba(255, 255, 255)' // white solid
      var obj = new fabric.Rect({
        height: 50,
        width: 50,
        fill: color
      })
      resetPreview();
      updateMapMode("draw");
      patternSourceCanvas = createDrawCanvas({
        height: 50 * initialZoom,
        width: 50 * initialZoom,
        obj: obj,
        initialZoom: initialZoom,
        color: color,
        mapLegendItemId: 67
      });
      canvas.freeDrawingBrush = new fabric['PencilBrush'](canvas);
      canvas.freeDrawingBrush.color = color;
      drawingLineWidthEl.value = 25,
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
      canvas.freeDrawingBrush.mapLegendItemId = 67;
    };
    eraseEl.onclick = function () {
      resetPreview();
      updateMapMode("draw");
      canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
      canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
    };
    straightLineEl.onclick = function () {
      if (this.checked) {
        endLineEl.style.display = '';
        points = [];
        lines = [];
        drawingLineWidthEl.value = 3;
        drawingLineValueEl.innerHTML = drawingLineWidthEl.value;
        var line = new fabric.Line(
          [
          ],
          {
            stroke: canvas.freeDrawingBrush.color,
            strokeDashArray: canvas.freeDrawingBrush.strokeDashArray,
            hasControls: true,
            hoverCursor: "default",
            originX: "center",
            originY: "center",
            selectable: true,
            connector: true,
            lockScalingX: true,
            lockScalingY: true,
            featureId: canvas.freeDrawingBrush.featureId
          }
        )
        updateMapMode("add")
        showDrawingSection()
        hideItemOpacitySection()
        hideItemScaleSection()
        setItemSelection(line)
      } 
    }
    fullScreenEl.onclick = function() {
      if (!document.fullscreenElement &&    // alternative standard method
          !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement ) {  // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    }
    seedDrawEl.onclick = function () {
      resetPreview()
      updateMapMode("draw");
      fabric.loadSVGFromURL('https://erxstorage.s3.us-west-1.amazonaws.com/images/seedling-solid.svg', function(objects, options) {
        var obj = fabric.util.groupSVGElements(objects, options);
        var texturePatternBrush = new fabric.PatternBrush(canvas);
        var color = 'rgba(102, 187, 106, 0.7)';
        var initialZoom = 0.40;
        resetPreview();
        obj.scaleToWidth(50);
        obj.set({
          left: 0,
          top: 0,
          fill: color
        })
        patternSourceCanvas = createDrawCanvas({
          height: 50 * initialZoom,
          width: 50 * initialZoom,
          obj: obj,
          initialZoom: initialZoom,
          color: color,
          mapLegendItemId: 68
        });
        texturePatternBrush.source = patternSourceCanvas.getElement();
        canvas.freeDrawingBrush = texturePatternBrush;
        drawingLineWidthEl.value = 25,
        drawingLineValueEl.innerHTML = drawingLineWidthEl.value,
        canvas.freeDrawingBrush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
        updateDrawCanvases(patternSourceCanvas, obj, canvas.freeDrawingBrush.mapLegendItemId);
      });
    };


    function createDrawCanvas(options) {
      document.getElementById('img-width-scale').value = options.initialZoom * 100;
      document.getElementById('img-width').innerHTML = options.initialZoom * 100;
      var patternSourceCanvas = new fabric.StaticCanvas();
      patternSourceCanvas.setDimensions({
        width: options.width || 50,
        height: options.height ||50
      })
      patternSourceCanvas.add(options.obj);
      patternSourceCanvas.setZoom(options.initialZoom)
      patternSourceCanvas.renderAll();
      preview_canvas.add(options.obj);
      preview_canvas.setZoom(options.initialZoom);
      preview_canvas.renderAll();
      canvas.freeDrawingBrush.color = options.color;
      canvas.freeDrawingBrush.mapLegendItemId = options.mapLegendItemId;

      return patternSourceCanvas
    }

    function updateDrawCanvases(patternSourceCanvas, obj, mapLegendItemId) {
      document.getElementById('img-width-scale').oninput = function () {
        var zoom = parseInt(this.value, 10) / 100
        var width = zoom * 50
        if ( obj.fromSVG ) { obj.scaleToWidth(width); }
        preview_canvas.setZoom(zoom);
        preview_canvas.setDimensions({
          width: width,
          height: width,
        });
        patternSourceCanvas.setZoom(zoom);
        patternSourceCanvas.setDimensions({
          width: width,
          height: width,
        });
        var items = canvas.getObjects()
        items.forEach(function(item, i) {
          if (item.mapLegendItemId == mapLegendItemId) {
            item.dirty = true
          }
        });
        canvas.requestRenderAll();
      };
    }

    // Polygon

    var points = [];
    var lines = [];

    constructionLimitsEl.onclick = function () {
      points = [];
      lines = [];
      drawingLineWidthEl.value = 5;
      drawingLineValueEl.innerHTML = drawingLineWidthEl.value;
      var line = new fabric.Line(
        [
        ],
        {
          stroke: "black",
          strokeDashArray: [5, 5],
          hasControls: true,
          hoverCursor: "default",
          originX: "center",
          originY: "center",
          selectable: true,
          connector: true,
          lockScalingX: true,
          lockScalingY: true,
          mapLegendItemId: 68
        }
      );
      updateMapMode("add");
      showDrawingSection();
      hideItemOpacitySection();
      hideItemScaleSection();
      setItemSelection(line);
    };

    if (fabric.PatternBrush) {

      var hLinePatternBrush = new fabric.PatternBrush(canvas);
      hLinePatternBrush.getPatternSrc = function() {

        var patternCanvas = fabric.document.createElement('canvas');
        patternCanvas.width = patternCanvas.height = 6;
        var ctx = patternCanvas.getContext('2d');

        ctx.strokeStyle = this.color;
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(0, 3);
        ctx.lineTo(6, 3);
        ctx.closePath();
        ctx.stroke();

        return patternCanvas;
      };

      var vLinePatternBrush = new fabric.PatternBrush(canvas);
      vLinePatternBrush.getPatternSrc = function() {

        var patternCanvas = fabric.document.createElement('canvas');
        patternCanvas.width = patternCanvas.height = 6;
        var ctx = patternCanvas.getContext('2d');

        ctx.strokeStyle = this.color;
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(3, 0);
        ctx.lineTo(3, 6);
        ctx.closePath();
        ctx.stroke();

        return patternCanvas;
      };

      var squarePatternBrush = new fabric.PatternBrush(canvas);
      squarePatternBrush.getPatternSrc = function() {

        var squareWidth = 6, squareDistance = 4;

        var patternCanvas = new fabric.StaticCanvas();
        patternCanvas.width = patternCanvas.height = squareWidth + squareDistance;
        var ctx = patternCanvas.getContext('2d');

        ctx.fillStyle = this.color;
        ctx.fillRect(0, 0, squareWidth, squareWidth);
        patternCanvas.renderAll();

        return patternCanvas;
      };

      var diamondPatternBrush = new fabric.PatternBrush(canvas);
      diamondPatternBrush.getPatternSrc = function() {

        var squareWidth = 10, squareDistance = 5;
        var patternCanvas = fabric.document.createElement('canvas');
        var rect = new fabric.Rect({
          width: squareWidth,
          height: squareWidth,
          angle: 45,
          fill: this.color
        });

        var canvasWidth = rect.getBoundingRect().width;

        patternCanvas.width = patternCanvas.height = canvasWidth + squareDistance;
        rect.set({ left: canvasWidth / 2, top: canvasWidth / 2 });

        var ctx = patternCanvas.getContext('2d');
        rect.render(ctx);

        return patternCanvas;
      };

    };
  }; // end if
});
